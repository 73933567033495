<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/health-banner.jpg">
		<div class="words">
				<p>
					<span>区域全民健康信息平台</span>
					软件定制化服务提供商
				</p>
			</div></div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>区域全民健康信息平台</span>
					<p class="crumbs"><a>您的位置：</a><a>产品</a>> <a>区域全民健康信息平台</a>
					</p>
				</div>
				<div class="content">
					<h3>平台业务需求</h3>
					<img src="@/assets/images/products/health/01.png" alt="平台业务需求">
					<h3>平台建设需求</h3>
					<img src="@/assets/images/products/health/02.png" alt="平台建设需求">
					<h3>平台与周边系统的关系</h3>
					<img src="@/assets/images/products/health/03.png" alt="平台与周边系统的关系">
					<h3>平台建设目标</h3>
					<img src="@/assets/images/products/health/04.png" alt="平台建设目标">
					<h3>平台目标概述</h3>
					<img src="@/assets/images/products/health/05.png" alt="平台目标概述">
					<h3>平台建设依据</h3>
					<img src="@/assets/images/products/health/06.png" alt="平台建设依据">
					<h3>平台方案-架构</h3>
					<img src="@/assets/images/products/health/07.png" alt="平台方案-架构">
					<h3>平台方案-技术体系架构</h3>
					<img src="@/assets/images/products/health/08.png" alt="平台方案-技术体系架构">
					<h3>平台方案-网络架构</h3>
					<img src="@/assets/images/products/health/09.png" alt="平台方案-网络架构">
					<h3>平台方案-建设内容-平台功能</h3>
					<img src="@/assets/images/products/health/10.png" alt="平台方案-建设内容-平台功能">
					<img src="@/assets/images/products/health/11.png" alt="平台方案-建设内容-平台功能">
					<h3>平台方案-技术组件</h3>
					<img src="@/assets/images/products/health/12.png" alt="平台方案-技术组件">
					<h3>平台方案-章节-数据治理平台</h3>
					<img src="@/assets/images/products/health/13.png" alt="平台方案-章节-数据治理平台">
					<h3>
						平台方案-数据治理平台-主流程</h3>
					<img src="@/assets/images/products/health/14.png" alt="平台方案-数据治理平台-主流程">
					<h3>平台方案-数据治理平台-数据采集</h3>
					<img src="@/assets/images/products/health/15.png" alt="系统架平台方案-数据治理平台-数据采集构">
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"
export default ({
	components: {
		MenuLayout
	},
	methods: {

	}
});
</script>
